import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import Button from 'react-bootstrap/Button';
import Ratio from "react-bootstrap/Ratio";
import Image from 'react-bootstrap/Image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
// import { Link } from 'react-scroll';

import logo from './assets/images/eqf_logo_rev.png';
import cardImg1 from './assets/images/ID01_checklist.png';
import cardImg2 from './assets/images/ID02_account.png';
import cardImg3 from './assets/images/ID03_complete.png';

import './App.scss';

const App = () => {

  return (
    <Container >
      <Row className="hero-video">
        <Col>
          <a href="https://educationquest.org"><Image src={logo} alt="EQF logo" aria-label="Redirect to EducationQuest's main site" /></a>
          <div className="bolonat heading">
            HAVE YOU HEARD ABOUT FAFSA?
          </div>        
          <Ratio aspectRatio="16x9" className='video'>
            <iframe src="https://www.youtube.com/embed/DnicmcpIzxI" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={1} title="FAFSA"></iframe>
          </Ratio>
          <AnchorLink offset='20' href='#whydoit' aria-label='scroll to next section'>
            <svg width="32px" height="32px" viewBox="0 0 16 16" className="bi bi-chevron-compact-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg" >
              <path fillRule="evenodd" d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67z" />
            </svg>
          </AnchorLink>
        </Col>
      </Row>
      <Row className="glitch first"></Row>
      <Row id="whydoit" className="why do">
        <Col className="no-image" xs={12} md={6}>
          <div className="bolonat heading">
            WHY DO IT?
          </div>
          <div className="text">
            Most jobs require education after high school.
          </div>
        </Col>
        <Col className="image career" xs={12} md={6}></Col>
      </Row>
      <Row className="why">
        <Col className="image student" xs={12} md={6}></Col>
        <Col className="no-image" xs={12} md={6}>
          <div className="text">
            The FAFSA opens the door to scholarships, grants, work-study, and low-interest student loans.
          </div>
        </Col>
      </Row>
      <Row className="why">
        <Col className="no-image" xs={12} md={6}>
          <div className="text">
            It’s your ticket to college, <br/>a degree, and a career.
          </div>
        </Col>
        <Col className="image grads" xs={12} md={6}></Col>
      </Row>
      <Row className="glitch"></Row>
      <Row className="how">
        <Col>
          <div className="bolonat heading">HOW DO I GET STARTED?</div>
          <CardGroup>
            <Card >
              <Card.Img variant="top" src={cardImg1} alt="document for download"/>
              <Card.Body>
                <Card.Text>
                  Gather all the stuff you need — here’s a checklist.
                </Card.Text>
              </Card.Body>
              <Button variant="info" className="text-white" href="https://educationquest.org/pdfs/FAFSAchecklist.pdf" download="FAFASchecklist.pdf" target="_blank">
                Download Checklist
              </Button>
            </Card>
            <Card>
              <Card.Img variant="top" src={cardImg2} alt="identification card" />
              <Card.Body>
                <Card.Text>
                  Create a Federal Student Aid account — one for you and one for your parent.
                </Card.Text>
              </Card.Body>
              <Button variant="info" className="text-white" href="https://studentaid.gov/" target="_blank">
                Create An Account
              </Button>
            </Card>
            <Card>
              <Card.Img variant="top" src={cardImg3} alt="fill out application"/>
              <Card.Body>
                <Card.Text>
                  Complete the FAFSA — it’s your application for federal, state, and college-based aid.
                </Card.Text>
              </Card.Body>
              <Button variant="info" className="text-white" href="https://studentaid.gov/h/apply-for-aid/fafsa" target="_blank">
                Complete the FAFSA
              </Button>
            </Card>
          </CardGroup>        
          <Row>
            <Col>
              <div className="details text"> Want more <a href="https://educationquest.org/pay" target="_blank" rel="noopener noreferrer">details</a>?</div>
            </Col>
          </Row>      
        </Col>
      </Row>
      <Row className="glitch last"></Row>
      <Row className="where">
        <Col>
          <div className="bolonat heading">WHERE CAN I GET HELP?</div>
          <Row>
            <Col md={{ span: 4, offset: 1 }} className="tools ">
              <div className="text">Use our FAFSA tools or contact us. It’s all <b>FREE</b>!</div>
              <div className="spacer"></div>
              
              <Button variant="info" href="https://educationquest.org/pay/fafsa-tools" target="_blank">
                FAFSA Tools
              </Button>            
            </Col>
            <Col xs={{ span: 10, offset: 2}} md={{ span: 5, offset: 1 }}>
              <Row className="pt-2">
                <Col>            
                  <b>Omaha</b> <br /><a href="tel:402.391.4033">402.391.4033</a>                
                </Col>
                <Col>
                  <b>Lincoln</b> <br /><a href="tel:402.475.5222">402.475.5222</a>                
                </Col>              
              </Row>
              <Row>
              <Col><b>Kearney</b> <br /><a href="tel:308.234.6310">308.234.6310</a></Col>
              <Col>
                <b>Scottsbluff</b> <br /><a href="tel:308.708.7199">308.708.7199</a></Col>
              </Row>
            </Col>
          </Row>          
        </Col>
      </Row>
      <Row className="footer">
        <Col>
          <ul className="list-inline p-0 m-0">
            <li className="list-inline-item">&#169; EducationQuest</li>          
            <li className="list-inline-item"><a href="https://educationquest.org/legal" target="_blank" rel="noopener noreferrer">Legal</a></li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
};

export default App;
